import { $date } from '@/plugins/dayjs'
import i18n from '@/scripts/app-configs/i18n-config'
import { preferredLanguage } from '@/scripts/app-configs/language/languages-configs'
import systemStoragePlugin from '@/store/plugins/systemStorage'
import getLocalFiles from '@/scripts/tools/get-local-files'

const APP_VERSION_NUMBER = '1.0.1'

import { CONFIG } from '@/scripts/app-configs/constants'

const appVersionStruct = () => {
  return {
    date: null,
    release: null,
    commit: null,
  }
}

const defaultSystemState = () => {
  return {
    UIReady: false,
    UILoadingMessage: 'Booting app...',
    version: {
      number: APP_VERSION_NUMBER,
      client: appVersionStruct(),
      build: appVersionStruct(),
    },
    language: preferredLanguage,
    measurementSystem: 'imperial',
    routeBeforeInactivity: {
      userId: null,
      routeFullPath: null,
    },
  }
}

export default {
  namespaced: true,
  state: {
    ...defaultSystemState(),
  },
  getters: {
    uiReady: (state) => state.UIReady,
    uiLoadingMessage: (state) => state.UILoadingMessage,
    appVersion: (state) => state.version.client,
    appVersionAnnotation: (state) => {
      const buildNumber = state.version?.client?.commit ? `(${state.version.client.commit.slice(0, 6)})` : ''

      return `v${state.version.number} ${buildNumber}`
    },
    appUpdated: (state) => {
      function formatVersionHash(versionObject) {
        return `${versionObject.commit}_${versionObject.release}`
      }
      const { build, client } = state.version

      return formatVersionHash(build) === formatVersionHash(client)
    },
    getLanguage: (state) => state.language,
    getMeasurementSystem: (state) => state.measurementSystem,
    getRouteBeforeInactivity: (state) => state.routeBeforeInactivity,
    defaultPage: (state, getters, rootState, rootGetters) => {
      const userExists = rootGetters['user/userExist']
      const userRole = rootGetters['user/getUserRole']

      const route = { name: 'ai-feed', params: {} }

      if (!userExists || !userRole) route.name = 'login'

      return route
    },
  },
  mutations: {
    setGlobalLoadingMessage: (state, message) => {
      state.UILoadingMessage = message
    },
    resetGlobalLoadingMessage: (state) => {
      state.UILoadingMessage = defaultSystemState().UILoadingMessage
    },
    setUIReadyState: (state, status) => {
      state.UIReady = status
    },
    changeLanguage: (state, lang) => {
      i18n.locale = lang
      state.language = i18n.locale
      $date.locale(i18n.locale)
    },
    changeMeasurementSystem: (state, units) => {
      state.measurementSystem = units || !CONFIG.environment.includes('eu') ? 'imperial' : 'international'
    },
    setAppVersion: (state, { context = 'client', versionObj = {} }) => {
      if (!state.version[context]) {
        console.error('[app-version] - Unable to set app version. Unsupported version context')

        return
      }
      state.version.number = APP_VERSION_NUMBER
      Object.keys(appVersionStruct()).forEach((key) => {
        state.version[context][key] = versionObj[key]
      })
    },
    setRouteBeforeInactivity(state, { userId, routeFullPath }) {
      state.routeBeforeInactivity.userId = userId
      state.routeBeforeInactivity.routeFullPath = routeFullPath
    },
    clearRouteBeforeInactivity(state) {
      Object.assign(state.routeBeforeInactivity, defaultSystemState().routeBeforeInactivity)
    },
  },
  actions: {
    async importPortalVersion({ commit }) {
      try {
        const { data: versionObj } = await getLocalFiles('version.json')

        commit('setAppVersion', { versionObj })

        return versionObj
      } catch (error) {
        return Promise.reject(error)
      }
    },
    resetSystem({ commit }) {
      // Remove persistent copy of this store.
      systemStoragePlugin.storage.removeItem(systemStoragePlugin.key)
      const systemDefaults = defaultSystemState()

      commit('changeMeasurementSystem', systemDefaults.measurementSystem)
    },
  },
}

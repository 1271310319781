import Vue, { computed, watch } from 'vue'
import store from '@/store'
import { useQueryClient, useMutation } from '@tanstack/vue-query'
import {
  UserMeType,
  UserFlagsType,
  UserOnboardingInfo,
} from '@/types/app-data/user'

export default function useUser() {
  const queryClient = useQueryClient()

  const userId = computed(() => store.getters['user/getUserId'])
  const isUserSet = computed(() => store.getters['user/userExist'])
  const authTokens = computed(() => {
    return {
      accessToken: store.getters['user/authentication/accessToken'],
      refreshToken: store.getters['user/authentication/refreshToken'],
    }
  })

  watch(isUserSet, () => {
    queryClient.removeQueries({ queryKey: ['fetch-user-me', 'fetch-user-flags', 'fetch-user-clients'] })
  })

  async function fetchMe(): Promise<UserMeType> {
    return queryClient.fetchQuery({
      queryKey: ['fetch-user-me'],
      staleTime: Infinity,
      queryFn: async () => {
        const { data: rawUserMe } = await Vue.$http('user/me/fetchMe')

        return {
          id: rawUserMe.user_id,
          firstname: rawUserMe.firstname,
          lastname: rawUserMe.lastname,
          picture: rawUserMe.picture,
          gender: rawUserMe.gender,
          username: rawUserMe.username,
          email: rawUserMe.email,
          role: rawUserMe.role.toLowerCase(),
          direct_message_address: rawUserMe.direct_message_address,
          telephone: rawUserMe.telephone,
          phone: rawUserMe.phone,
          measurement_system: rawUserMe.measurement_system,
          language: rawUserMe.language,
          sendbird: rawUserMe.sendbird,
          externalPhoneNumber: rawUserMe.external_phone_number,
        }
      },
    })
  }

  async function fetchUserFlags(): Promise<UserFlagsType> {
    return queryClient.fetchQuery({
      queryKey: ['fetch-user-flags'],
      staleTime: Infinity,
      queryFn: async () => {
        const { data: { data: rawUserFlags } } = await Vue.$http('user/me/flags')

        return {
          isAIPt: rawUserFlags.is_ai || false,
          isLeadPt: rawUserFlags.is_lead || false,
          isTreatmentPt: rawUserFlags.is_treatment_pt || false,
          isIntakePt: rawUserFlags.is_intake_pt || false,
          isClinicalAssistantManager: rawUserFlags.is_clinical_assistant_manager || false,
          hasProviderEnabled: rawUserFlags.has_provider_enabled || false,
        }
      },
    })
  }

  async function fetchUserOnboardingInfo(): Promise<UserOnboardingInfo | null> {
    return queryClient.fetchQuery({
      queryKey: ['fetch-user-onboarding-info', userId],
      staleTime: Infinity,
      queryFn: async () => {
        const { data: { information } } = await Vue.$http('general/user/getTherapistInfo', userId.value)

        return information
      },
    })
  }

  const {
    mutateAsync: updateUser,
  } = useMutation({
    mutationFn: (payload) => Vue.$http('user/updateProfessional', userId.value, { body: payload }),
    onSuccess: () => {
      // TODO When can use setQueryData method here when app is a bit more stable and we ensure payloads and store data have the exact names
      queryClient.removeQueries({ queryKey: ['fetch-user-me'] })
    },
  })

  return {
    userId,
    isUserSet,
    authTokens,
    fetchMe,
    fetchUserFlags,
    fetchUserOnboardingInfo,
    updateUser,
  }
}

<template>
  <sword-modal v-if="isModalOpen" @close="closeModal">
    <template slot="header">
      <h4 class="center-text">
        {{ $t('RECOMMENDATION_REASONS') }}
      </h4>
    </template>
    <template slot="body">
      <loading-screen v-if="isFetching" />
      <p v-else-if="errorMessage">
        {{ $t(errorMessage) }}
      </p>
      <ul v-else class="recommendation-reasons__list">
        <li v-for="reason in recommendationReasons" :key="`reason-${reason}`" class="recommendation-reasons__list-item">
          {{ $t(reason) }}
        </li>
      </ul>
    </template>
    <template slot="footer">
      <sword-button-wuk
        theme="secondary"
        :text="errorMessage ? 'COMMON.RETRY' : 'COMMON.CLOSE'"
        @click.native="errorMessage ? retry() : closeModal()"
      />
    </template>
  </sword-modal>
</template>

<script>
import Vue, { computed } from 'vue'

// Components
import LoadingScreen from '@/components/feedbacks/LoadingScreen.vue'
import SwordModal from '@/components/modals/SwordModal.vue'

// Utils
import { triggers } from '@/scripts/global-modals-commands'

export default {
  name: 'RecommendationReasons',
  components: {
    LoadingScreen,
    SwordModal,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isModalOpen = computed(() => props.modalOpen)

    return { isModalOpen }
  },
  data() {
    return {
      isFetching: false,
      recommendationReasons: [],
      errorMessage: null,
    }
  },
  computed: {
    recommendationUuid() {
      return this.$store.getters['recommendations/selectedRecommendationUuid']
    },
  },
  watch: {
    isModalOpen(newValue) {
      if (newValue) {
        this.fetchRecommendationReasons()
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('recommendations/resetSelectedRecommendationUuid')
  },
  methods: {
    async fetchRecommendationReasons() {
      try {
        this.isFetching = true
        const { data } = await Vue.$http('recommendations/fetchRecommendationReasons', this.recommendationUuid)

        this.recommendationReasons = data.justifications
      } catch (error) {
        this.errorMessage = 'copy_89'
        console.error(`[pp-recommendation-reasons] Error fetching recommendation ${this.recommendationUuid} reasons.`, error)
      } finally {
        this.isFetching = false
      }
    },
    closeModal() {
      triggers.closeRecommendationsReasons()
    },
    retry() {
      this.errorMessage = ''
      this.fetchRecommendationReasons()
    },
  },
}
</script>

<style lang="scss" scoped>
.recommendation-reasons {
  &__list {
    list-style-type: circle;
    padding: 0 2rem;
    text-align: start;
  }

  &__list-item {
    @extend %-t_medium;
    list-style-type: disc;
    padding: 0.6rem 0;
  }
}
</style>

<template>
  <sword-modal
    v-if="showTimeoutModal"
    type="warning"
    header="copy_4416"
    @close="resetInactivity"
  >
    <template #body>
      <p class="-pb_2 warning-text">
        {{ $t('copy_4417') }}
      </p>
      <p> {{ stopWatch }} </p>
    </template>
    <template #footer>
      <sword-button-wuk theme="primary" text="copy_2" @click.native="resetInactivity" />
    </template>
  </sword-modal>
</template>

<script>
import { computed, ref, watch } from 'vue'
import useActivityTracker from '@swordhealth/ui/composables/activity-tracker'
import useTimer from '@swordhealth/ui/composables/timer'
import useBroadcastTabChannel from '@swordhealth/ui/composables/broadcast-message'

import store from '@/store'
import router from '@/router'

import SwordModal from '@/components/modals/SwordModal.vue'
import { AUTH_COOKIE, SESSION_TIMEOUT_CONFIG } from '@/scripts/app-configs/constants'
import { CONFIG } from '@/scripts/app-configs/constants'

export default {
  name: 'TimeoutManager',
  components: {
    SwordModal,
  },
  setup() {

    const { onMessage, postMessage } = useBroadcastTabChannel(CONFIG.broadcastChannelName)

    const resetActivityBroadcastType = 'update:user-activity-reset'
    const requireManualActionBroadcastType = 'update:user-manual-action-required'
    const userActionBroadcastType = 'update:user-activity-event'

    const IDLE_TIME_UNTIL_AUTO_LOGOUT = Number(SESSION_TIMEOUT_CONFIG.autoLogoutTimeout) // seconds
    const TIME_TO_MANUAL_ACTION_BEFORE_LOGOUT = 60 // seconds

    const idleTimeBeforeManualAction = IDLE_TIME_UNTIL_AUTO_LOGOUT - TIME_TO_MANUAL_ACTION_BEFORE_LOGOUT

    const showTimeoutModal = ref(false)
    const fireUserIdled = computed(() => store.getters['user/authentication/isAuthenticated'])

    const resetInactivity = (broadcastEv = true) => {
      if (broadcastEv) postMessage(userActionBroadcastType, resetActivityBroadcastType)
      updateDocumentTitleWithTimer()
      showTimeoutModal.value = false
      resetIdle()
      resetTimer()
    }

    onMessage(userActionBroadcastType, (message) => {
      if (message === requireManualActionBroadcastType) requestManualAction()
      else if (message === resetActivityBroadcastType) resetInactivity(false)
    })

    const logoutUser = async () => {
      await store.dispatch('user/authentication/logout', { sameUserLoginRoute: router.currentRoute.fullPath })
      await router.replace({ name: 'logout' }).catch(() => {})
    }

    const onTimerEnds = async () => {
      resetInactivity(false)
      await logoutUser()
    }

    const updateDocumentTitleWithTimer = (currentTimer) => {
      const TIMER_REGEX = /(\[[0-9]{2}:[0-9]{2}\]\s)?(.+)/g

      document.title = document.title.replace(TIMER_REGEX, (curr, timer, title) => {
        return currentTimer ? `[${currentTimer}] ${title}` : title
      })
    }

    const requestManualAction = () => {
      if (showTimeoutModal.value) return
      restartTimer()
      updateDocumentTitleWithTimer(stopWatch.value)
      showTimeoutModal.value = true
    }

    const activityTrackerOptions = {
      secondsUntilIdle: idleTimeBeforeManualAction,
      activityCookieName: SESSION_TIMEOUT_CONFIG.sessionTimerStartCookieName,
      cookieDomain: AUTH_COOKIE.token_domain,
    }

    const { idle, resetIdle } = useActivityTracker(activityTrackerOptions)
    const { restart: restartTimer, reset: resetTimer, stopWatch } = useTimer(TIME_TO_MANUAL_ACTION_BEFORE_LOGOUT, onTimerEnds)

    // On tab boot, inform other tabs about it
    postMessage(userActionBroadcastType, resetActivityBroadcastType)

    watch(() => idle.value, (val) => {
      if (!val || !fireUserIdled.value) return
      // Ensure all tabs goes to manual action required state despite clock asynchronisms
      postMessage(userActionBroadcastType, requireManualActionBroadcastType)
      requestManualAction()
    })

    watch(() => stopWatch.value, (val) => {
      if (!showTimeoutModal.value) return
      updateDocumentTitleWithTimer(val)
    })

    return {
      stopWatch,
      showTimeoutModal,
      resetInactivity,
    }
  },
}
</script>
<style lang="scss" scoped>
.warning-text {
  max-width: 30rem;
}
</style>

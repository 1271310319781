import { deepCopyObj } from '@/scripts/tools/helper-tools'
import Vue from 'vue'
import { prescritonTypesConfigs } from '@/scripts/configs/AI-options'
import { PRESCRITPION_FLOW_TYPES } from '@/scripts/configs/prescription/prescription-configs'
import { shDate } from '@/utils/filters'

const defaultExercisesFormat = {
  invalidSessionFill: false,
  therapyOrigin: {
    therapyID: null,
    therapySide: null,
  }, // Stores the reference therapy that loaded exercises belong
  list: [], // Full therapy exercises list/possible to prescribe
  added: [], // Exercises added to session
  removed: [], // Exercises blacklisted/removed from session
  reAdded: [], // Exercises that were blacklisted but then reAdded to session
  sideFilter: null,
}

const defaultSessionBody = function genDefaultSessionStruct() {
  return {
    id: null, // Gets set is fetched some existing session
    therapy: {}, // TODO FILL THIS WITH EXPECTED PROPERTIES
    therapySide: null,
    therapy_new: { // We need to migrate from the above to this one. Can't do that at a time.
      id: null, // Reference to the it from institution therapies,
      code: null, // Reference to the it from institution therapies,
      side: null, // Specific to current session
    },
    positions: [],
    daily_frequency: 1, // Default daily frequency value
    weekly_frequency: 3, // Default weekly frequency value
    sessionCategory: '',
    custom_name: null,
    goal: null,
    exercisesConditions: {}, // TODO this may not be needed stored
    numOfExercises: 0, // Exercise counter (UI only) // TODO MAYBE MOVE TO GETTER
    exerciseMaxOrder: 1, // Manual reorder (UI only) // TODO MAYBE MOVE TO GETTER
    exercises: deepCopyObj(defaultExercisesFormat),
    estimatedSessionTime: '',
  }
}

/**
 * @description Use to reset prev selected filters that are dependent of a new selection
 *              and got invalidate by filter redefinition.
 *              Pass want you want to leave set and remaining params will get reset
 * @param id
 * @param phase
 * @param sessionId
 * @returns {{protocolId: (*|null), protocolSessionId: (*|null), protocolPhase: (*|null)}}
 */

const protocolFiltersDiscardSetter = function protocolFilterDiscardFn({ protocolId, protocolPhase, protocolSessionId } = {}) {
  return {
    protocolId: protocolId || null,
    protocolPhase: protocolPhase || null,
    protocolSessionId: protocolSessionId || null,
  }
}

const defaultProtocolFilters = function genFefaultProtocolFilterStruct() {
  return {
    status: null,
    name: null,
    therapy: {
      id: null,
      side: null,
    },
    session: {
      id: null,
      definitionId: null,
      name: null,
      spec: {},
      goal: '',
    },
    filters: protocolFiltersDiscardSetter(),
    statusJustification: {
      reason: '',
      explanation: '',
    },
  }
}

const defaultResistancePropagationTracking = () => {
  return {
    enabled: {},
    disabled: {},
  }
}

export default {
  namespaced: true,
  state: {
    prescriptionType: '',
    sessionsCategories: [],
    exercisesWithResistance: {
      propagationTracking: defaultResistancePropagationTracking(),
    },
    protocol: defaultProtocolFilters(),
    currentSession: defaultSessionBody(),
  },
  getters: {
    getSessionId: (state) => state.currentSession.id,
    isProtocolPrescription: (state) => state.prescriptionType === prescritonTypesConfigs.protocol.option.optValue,
    isCustomPrescription: (state) => state.prescriptionType === prescritonTypesConfigs.custom.option.optValue,
    currentSessionFillIsInvalid: (state) => state.currentSession.exercises.invalidSessionFill,
    getPrescriptionType: (state) => state.prescriptionType,
    getAvailableSessionCategories: (state) => state.sessionsCategories,
    getSessionCategory: (state) => state.currentSession.sessionCategory,
    getProtocolStatus: (state) => state.protocol.status,
    getProtocolName: (state) => state.protocol.name,
    getProtocolSessionId: (state) => state.protocol.session.id,
    getProtocolSessionDefinitionId: (state) => state.protocol.session.definitionId,
    getProtocolSessionName: (state) => state.protocol.session.name,
    getProtocolSessionGoal: (state) => state.protocol.session.goal,
    getCurrentGoal: (state, getters, rootState, rootGetters) => {
      if (state.currentSession.goal) {
        return state.currentSession.goal
      }

      const flowType = rootGetters['prescription/flowType']
      const isProtocolPrescription = flowType === PRESCRITPION_FLOW_TYPES.ADD_PROTOCOL

      return isProtocolPrescription ? getters.getProtocolSessionGoal : ''
    },
    getProtocolSessionSpec: (state) => state.protocol.session.spec,
    getProtocolTherapy: (state) => (filter) => {
      switch (filter) {
        case 'therapyId':
          return state.protocol.therapy.id
        case 'therapySide':
          return state.protocol.therapy.side
        default:
          return state.protocol.therapy
      }
    },
    getProtocolFilter: (state) => (filter) => {
      switch (filter) {
        case 'protocolId':
          return state.protocol.filters.protocolId
        case 'protocolPhase':
          return state.protocol.filters.protocolPhase
        case 'protocolSessionId':
          return state.protocol.filters.protocolSessionId
        default:
          return state.protocol.filters
      }
    },
    getProtocolStatusJustification: (state) => (filter) => {
      switch (filter) {
        case 'reason':
          return state.protocol.statusJustification.reason
        case 'explanation':
          return state.protocol.statusJustification.explanation
        default:
          return state.protocol.statusJustification
      }
    },
    getCurrentSession: (state) => state.currentSession,
    getCurrentSessionCustomName: (state) => state.currentSession.custom_name,
    getCurrentTherapyRef: (state) => state.currentSession.therapy_new, // getCurrentTherapyExercises: state => state.currentSession.therapy.exercises,
    getCurrentExercises: (state) => state.currentSession.exercises,
    getAddedExercises: (state) => state.currentSession.exercises.added,
    getCurrentExercisesSideFilter: (state) => state.currentSession.exercises.sideFilter,
    getExercisesConditions: (state) => state.currentSession.exercisesConditions,
    getExerciseConditions: (state) => (exerciseID) => state.currentSession.exercisesConditions[exerciseID],
    getResistancePropagationTracking: (state) => state.exercisesWithResistance.propagationTracking,
    getMaxExerciseOrder: (state) => {
      return state.currentSession.exerciseMaxOrder
    },
    alreadyHaveExercisesLoaded: (state) => (therapyID, therapySide) => {
      const listNotEmpty = !!state.currentSession.exercises.list.length
      const storedExercisesOrigin = state.currentSession.exercises.therapyOrigin
      const isRequestedTherapy = storedExercisesOrigin.therapyID === therapyID && storedExercisesOrigin.therapySide === therapySide

      return isRequestedTherapy && listNotEmpty
    },
    isExerciseSelected: (state) => (exerciseID) => state.currentSession.exercises.added.some(({ id }) => id === exerciseID),
    isExerciseRemoved: (state) => (exerciseCode) => state.currentSession.exercises.removed.includes(exerciseCode),
    getEstimatedSessionTime: (state) => state.currentSession.estimatedSessionTime,
    exercisesWithActiveResistanceByCategory: (state, getters, rootState, rootGetters) => {
      const { enabled, disabled } = getters.getResistancePropagationTracking
      const exercisesWithResistance = rootGetters['patient/prescription/getExerciseCodesWithWeightForCurrentSession']

      const allAddedCategories = [...new Set(Object.keys(exercisesWithResistance).concat(Object.keys(enabled)))]
      const currentExercisesWithResistance = allAddedCategories.reduce((acc, category) => {
        const allExercisesAddedForCategory = (exercisesWithResistance[category] || []).concat(enabled[category] || [])
        const categoryExercises = [...new Set(allExercisesAddedForCategory)].filter((ex) => {
          return !disabled[category] || disabled[category].indexOf(ex) === -1
        })

        if (categoryExercises.length) {
          acc[category] = categoryExercises
        }

        return acc
      }, {})

      return currentExercisesWithResistance
    },
  },
  mutations: {
    invalidateSessionFill(state, invalidate = true) {
      state.currentSession.exercises.invalidSessionFill = invalidate
    },
    setPrescriptionType(state, type) {
      state.prescriptionType = type
    },
    setAvailableSessionCategories(state, categories) {
      Vue.set(state, 'sessionsCategories', categories)
    },
    setSessionCategory(state, category) {
      state.currentSession.sessionCategory = category
    },
    resetSessionCategory(state) {
      state.currentSession.sessionCategory = ''
    },
    setProtocolName(state, protocolName) {
      state.protocol.name = protocolName
    },
    setProtocolSessionGoal(state, protocolGoal) {
      state.protocol.session.goal = protocolGoal
    },
    setProtocolSessionName(state, sessionName) {
      state.protocol.session.name = sessionName
    },
    setProtocolSessionId(state, sessionId) {
      state.protocol.session.id = sessionId
    },
    setProtocolSessionDefinitionId(state, definitionId) {
      state.protocol.session.definitionId = definitionId
    },
    setProtocolSessionSpec(state, sessionSpec) {
      state.protocol.session.spec = sessionSpec
    },
    setProtocolTherapyFilter(state, { therapyId, therapySide }) {
      if (therapyId) {
        state.protocol.therapy.id = therapyId
      }
      if (therapySide) {
        state.protocol.therapy.side = therapySide
      }
    },
    setProtocolFilter(state, { filter, value }) {
      switch (filter) {
        case 'protocolId': {
          if (state.protocol.filters.protocolId === value) {
            break
          }
          state.protocol.filters = protocolFiltersDiscardSetter({ protocolId: value })
          break
        }
        case 'protocolPhase': {
          if (state.protocol.filters.protocolPhase === value) {
            break
          }
          const { protocolId } = state.protocol.filters

          state.protocol.filters = protocolFiltersDiscardSetter({
            protocolId,
            protocolPhase: value,
          })
          break
        }
        case 'protocolSessionId': {
          if (state.protocol.filters.protocolSessionId === value) {
            break
          }
          state.protocol.filters.protocolSessionId = value
          break
        }
        default: {
          console.log('Unable to find requested filter')
          break
        }
      }
    },
    setProtocolStatus(state, status) {
      state.protocol.status = status
    },
    setProtocolStatusJustification(state, { filter, value }) {
      switch (filter) {
        case 'reason':
          state.protocol.statusJustification.reason = value
          break
        case 'explanation':
          state.protocol.statusJustification.explanation = value
          break
        default:
          console.log('Unable to find requested filter')
          break
      }
    },
    setCurrentTherapyProperties(state, payload) {
      Object.keys(payload).forEach((therapyProp) => {
        Vue.set(state.currentSession.therapy, therapyProp, payload[therapyProp])
      })
    },
    setCurrentTherapy(state, therapy) {
      Vue.set(state.currentSession, 'therapy', therapy)
      if (!therapy) {
        return
      }
      Vue.set(state.currentSession.therapy_new, 'id', therapy.id)
      Vue.set(state.currentSession.therapy_new, 'code', therapy.code)
    },
    setCurrentTherapySide(state, side) {
      state.currentSession.therapySide = side
      Vue.set(state.currentSession.therapy_new, 'side', side)
    },
    setCurrentWeeklyFrequency(state, weeklyFrequency) {
      state.currentSession.weekly_frequency = weeklyFrequency
    },
    setCurrentFullExercisesList(state, { therapyId, therapySide, exercisesList }) {
      state.currentSession.exercises.therapyOrigin.therapyID = therapyId
      state.currentSession.exercises.therapyOrigin.therapySide = therapySide
      state.currentSession.exercises.list = exercisesList
    },
    setSessionId(state, id) {
      state.currentSession.id = id || null
    },
    setCurrentCustomName(state, payload) {
      state.currentSession.custom_name = payload || null
    },
    setCurrentGoal(state, payload) {
      state.currentSession.goal = payload || null
    },
    setExercisesConditions(state, payload) {
      state.currentSession.exercisesConditions = payload
    },
    updateExerciseMaxOrder(state, newVal) {
      switch (newVal) {
        case 'inc':
          state.currentSession.exerciseMaxOrder++
          break
        case 'dec':
          state.currentSession.exerciseMaxOrder--
          break
        default:
          if (state.currentSession.exerciseMaxOrder < newVal) {
            state.currentSession.exerciseMaxOrder = newVal
          }
          break
      }
    },
    setAddedExercises(state, exercises) {
      Vue.set(state.currentSession.exercises, 'added', exercises)
    }, // TODO NOT A PAYLOAD??!!
    addExercise(state, exercise, num = 1) {
      for (let i = 0; i < num; i++) {
        exercise.session_exercise_id = null // to insert on DB instead of update it
        exercise.order = state.currentSession.exerciseMaxOrder // Newly added exercises go to the end of the pile
        state.currentSession.exercises.added.push(exercise)
      }
    },
    removeAllAddedExercises(state) {
      Vue.set(state.currentSession.exercises, 'added', [])
    },
    setPositions(state, positions = []) {
      Vue.set(state.currentSession, 'positions', positions)
    },
    setRemovedExercises(state, exercises = []) {
      Vue.set(state.currentSession.exercises, 'removed', exercises)
    },
    setReAddedExercises(state, exercises = []) {
      Vue.set(state.currentSession.exercises, 'reAdded', exercises)
    },
    removeExerciseById(state, exerciseID) {
      const foundExerciseIndex = state.currentSession.exercises.added.findIndex((ex) => ex.id === exerciseID)

      state.currentSession.exercises.added.splice(foundExerciseIndex, 1)
    },
    removeExercisesByID(state, { id }) {
      Vue.set(state.currentSession.exercises, 'added', state.currentSession.exercises.added.filter((exercise) => exercise.id !== id))
    },
    removeExerciseByIndex(state, indexToDelete) {
      state.currentSession.exercises.added.splice(indexToDelete, 1)
    },
    updateNumOfExercises(state, orientation) {
      switch (orientation) {
        case 'inc':
          state.currentSession.numOfExercises++
          break
        case 'dec':
          state.currentSession.numOfExercises--
          break
        case 'reset':
          state.currentSession.numOfExercises = 0
          break
        default:
          state.currentSession.numOfExercises += Number(orientation)
          break
      }
    },
    discardCurrentSession(state) {
      Vue.set(state, 'currentSession', defaultSessionBody())
    },
    discardProtocolFilters(state) {
      state.prescriptionType = ''
      Vue.set(state, 'protocol', defaultProtocolFilters())
    },
    discardCurrentExercisesList(state) {
      Vue.set(state.currentSession, 'exercises', deepCopyObj(defaultExercisesFormat))
    },
    updateListedExerciseByExerciseID(state, { id, payload }) {
      const exerciseToEdit = state.currentSession.exercises.list.find((ex) => ex.id === id)

      if (exerciseToEdit) {
        Object.assign(exerciseToEdit, payload)
      }
    },
    updateAddedExerciseByID(state, { id, payload }) {
      const exerciseToEdit = state.currentSession.exercises.added.find((ex) => ex.id === id)

      if (exerciseToEdit) {
        Object.assign(exerciseToEdit, payload)
      }
    },
    updateAddedExerciseByIndex(state, { index, payload }) {
      const exerciseToEdit = state.currentSession.exercises.added[index]

      if (exerciseToEdit) {
        Object.assign(exerciseToEdit, payload)
      }
    },
    updateAllAddedExerciseByID(state, { id, payload }) {
      const exercisesAddedToEdit = state.currentSession.exercises.added.filter((ex) => ex.id === id)

      exercisesAddedToEdit.forEach((ex) => {
        Object.assign(ex, payload)
      })
    },
    setExercisesSelectionSideFilter(state, side) {
      state.currentSession.exercises.sideFilter = side
    },
    resetExercisesSelectionSideFilter(state) {
      state.currentSession.exercises.sideFilter = null
    },
    setEstimatedSessionTime(state, estimatedSessionTime) {
      state.currentSession.estimatedSessionTime = estimatedSessionTime
    },
    trackResistanceChange(state, { action, category, code }) {
      Vue.set(state.exercisesWithResistance.propagationTracking[action], category, [code])
    },
    resetTrackedResistanceChanges(state) {
      Vue.set(state.exercisesWithResistance, 'propagationTracking', defaultResistancePropagationTracking())
    },
    trackedResistanceChange(state, { action, category, code }) {
      const categoryBeingTracked = state.exercisesWithResistance.propagationTracking[action][category]
      const codeAlreadyOnForCategory = categoryBeingTracked?.indexOf(code) !== -1

      if (!categoryBeingTracked) {
        Vue.set(state.exercisesWithResistance.propagationTracking[action], category, [code])
      } else if (!codeAlreadyOnForCategory) {
        state.exercisesWithResistance.propagationTracking[action][category].push(code)
      }

    },
    removeTrackedResistanceChangeIfExisting(state, { action, category, code }) {
      const categoryBeingTracked = state.exercisesWithResistance.propagationTracking[action][category]
      const indexOnTracking = categoryBeingTracked?.indexOf(code)

      if (!categoryBeingTracked || indexOnTracking === -1) {
        return
      }
      state.exercisesWithResistance.propagationTracking[action][category].splice(indexOnTracking, 1)
      if (!state.exercisesWithResistance.propagationTracking[action][category].length) {
        Vue.delete(state.exercisesWithResistance.propagationTracking[action], category)
      }
    },
  },
  actions: {
    setResistancePropagationChange({ getters, commit, rootGetters }, { category, code, toState }) {

      const categoriesToTarget = category === 'all' ? getters.getAvailableSessionCategories.map((c) => c.name) : [category]

      const activeResistancesPrePrescription = rootGetters['patient/prescription/getExerciseCodesWithWeightForCurrentSession']

      const existPrePrescription = (categoryToCheck, exCode) => {
        const resistanceCategory = activeResistancesPrePrescription[categoryToCheck]
        const indexOfResistanceCode = resistanceCategory?.indexOf(exCode)

        return resistanceCategory && indexOfResistanceCode !== -1
      }

      categoriesToTarget.forEach((categoryName) => {

        if (toState === 'enabled') {
          // Check if previous added to disabled. If so, remove entry from there
          commit('removeTrackedResistanceChangeIfExisting', { action: 'disabled', category: categoryName, code })

          if (existPrePrescription(categoryName, code)) {
            return
          }
          /*
          *  If category still not existing on enabled, create it
          *  If already exist on category, do nothing (ex: added to all after individually added)
          * */
          commit('trackedResistanceChange', { action: 'enabled', category: categoryName, code })

        } else if (toState === 'disabled') {
          // Check if previous added to enabled. If so, remove entry from there and return
          commit('removeTrackedResistanceChangeIfExisting', { action: 'enabled', category: categoryName, code })
          if (!existPrePrescription(categoryName, code)) {
            return
          }
          // If category still not existing on disabled, create it
          commit('trackedResistanceChange', { action: 'disabled', category: categoryName, code })
        }
      })
    },
    toggleAddedExerciseInstanceResistance({ commit }, { exerciseId, resistanceStatus }) {
      commit('updateAllAddedExerciseByID', { id: exerciseId, payload: { resistance: resistanceStatus } })
    },
    invalidateCurrentProtocolFill({ dispatch, commit }) {
      commit('invalidateSessionFill')
      dispatch('prescription/backupSession/discardBackupPrescription', null, { root: true })
    },
    setProtocolTherapy({ commit, dispatch }, { therapyId, therapySide }) {
      commit('resetExercisesSelectionSideFilter')
      commit('setProtocolTherapyFilter', { therapyId, therapySide })
      if (therapyId) {
        dispatch('setCurrentTherapyById', therapyId)
      }
      if (therapySide) {
        commit('setCurrentTherapySide', therapySide)
      }
    },
    async fetchTherapyExercisesConditionsByPatient({ commit }, { patientId, therapyId }) {
      try {
        const { data: exercisesWithConditions } = await Vue.$http('prescription/patient/getTherapyExercisesConditionsByPatient', {
          patientId,
          therapyId,
          ignoreFromDefaultSessions: true,
        })

        commit('prescription/currentSession/setExercisesConditions', exercisesWithConditions, { root: true })

        return exercisesWithConditions
      } catch (error) {
        console.error(`Error fetching therapy exercises conditions of therapy for patient. therapyID: ${therapyId} patientID: ${patientId}`, error)

        return error
      }
    },
    setCurrentTherapyById({ rootGetters, commit }, therapyID) {
      const therapy = rootGetters['prescription/therapies/getInstitutionTherapyByID'](therapyID)

      commit('setCurrentTherapy', therapy)
    },
    selectTherapyByCode({ rootGetters, commit }, therapyCode) {
      const therapy = rootGetters['prescription/therapies/getInstitutionTherapyByCode'](therapyCode)

      commit('setCurrentTherapy', therapy)
    },
    removeAllExercisesFromSession({ getters, commit, dispatch }) {
      const addedExercisesInstances = getters.getCurrentExercises.added

      if (!addedExercisesInstances.length) {
        return
      }
      // Get all IDs we need to update on list object so UI shows correct series info
      const affectedExercisesIDs = [...new Set(addedExercisesInstances.map((ex) => ex.id))]

      // Updated list data
      affectedExercisesIDs.forEach((exerciseID) => {
        dispatch('updateExerciseSeries', {
          id: exerciseID,
          orientation: 'reset',
        })
      })
      commit('removeAllAddedExercises')
      commit('updateNumOfExercises', 'reset')
    },
    addExerciseSeriesToSession({ getters, rootGetters, commit, dispatch }, { exercise, hasCachedSeries = false }) {
      // hasCachedSeries checks if there are series added to a selected exercise, which was then unselected and selected again
      if (!hasCachedSeries) {
        dispatch('updateExerciseSeries', {
          id: exercise.id,
          orientation: 'inc',
        })
      }
      const updateExercisesOrientation = hasCachedSeries ? exercise.series : 'inc'

      commit('updateNumOfExercises', updateExercisesOrientation)
      commit('updateExerciseMaxOrder', updateExercisesOrientation)
      // Use a copy to deal with multiple exercises order
      const exerciseCopy = deepCopyObj(exercise)
      const nrExercisesToAdd = hasCachedSeries ? exerciseCopy.series : 1

      exerciseCopy.series = 1

      const sessionCategory = rootGetters['prescription/currentSession/getSessionCategory']
      const activeResistancesForSessionCategory = getters.exercisesWithActiveResistanceByCategory[sessionCategory] || []

      exerciseCopy.resistance = activeResistancesForSessionCategory.indexOf(exercise.code) !== -1

      for (let i = 0; i < nrExercisesToAdd; i++) {
        commit('addExercise', exerciseCopy)
      }
    },
    removeExerciseFromSessionByID({ commit, dispatch }, exerciseID) {
      commit('updateNumOfExercises', 'dec')
      dispatch('updateExerciseSeries', {
        id: exerciseID,
        orientation: 'dec',
      })
      commit('removeExerciseById', exerciseID)
    },
    removeLastExerciseFromSessionByID({ getters, commit, dispatch }, exerciseID) {
      commit('updateNumOfExercises', 'dec')

      dispatch('updateExerciseSeries', {
        id: exerciseID,
        orientation: 'dec',
      })

      // Finding index of higher order exercise instance
      const higherOrderIndex = getters.getCurrentExercises.added.reduce((higherIndex, curr, currIndex, origin) => {
        const compareIndex = higherIndex === -1 ? 0 : higherIndex
        const checkEx = curr.id === exerciseID
        const orderIsHigher = higherIndex === -1 || curr.order >= origin[compareIndex].order

        return checkEx && orderIsHigher ? currIndex : higherIndex
      }, -1)

      commit('removeExerciseByIndex', higherOrderIndex)
    },
    removeAllExercisesFromSessionByID({ commit }, { exerciseID, numberOfExercisesToUpdate }) {
      commit('updateNumOfExercises', numberOfExercisesToUpdate)
      commit('removeExercisesByID', { id: exerciseID, payload: { series: 0 } })
    },
    updateExerciseSeries({ getters, commit, dispatch }, { id, orientation }) {
      let currentExerciseSeries = getters.getCurrentExercises.list.find((ex) => ex.id === id).series

      switch (orientation) {
        case 'inc':
          currentExerciseSeries++
          // When adding an exercise, remove its justification for previous changes
          if (currentExerciseSeries === 1) {
            dispatch('removeJustificationByExerciseID', {
              exerciseID: id,
            })
          }
          break
        case 'dec':
          currentExerciseSeries--
          // When removing an exercise, remove its justification for previous changes
          if (currentExerciseSeries === 0) {
            dispatch('removeJustificationByExerciseID', {
              exerciseID: id,
            })
          }
          break
        case 'reset':
          currentExerciseSeries = 0
          break
        default:
          currentExerciseSeries += Number(orientation)
          break
      }
      commit('updateListedExerciseByExerciseID', {
        id,
        payload: { series: currentExerciseSeries },
      })
    },
    removeJustificationByExerciseID({ rootGetters, commit }, { exerciseID }) {
      const justifications = rootGetters['prescription/justification/getChangesJustifications']

      if (justifications[exerciseID]) {
        commit('prescription/justification/removeJustificationByExerciseID', exerciseID, { root: true })
      }
    },
    updateExercisesByID({ commit }, { id, payload }) {
      commit('updateAllAddedExerciseByID', { id, payload })
      commit('updateListedExerciseByExerciseID', { id, payload })
    },
    toggleExerciseSelectionByID({ getters, dispatch }, exercise) {
      const newSelectedStatus = !getters.isExerciseSelected(exercise.id)

      if (newSelectedStatus) {
        const hasCachedSeries = !!exercise.series

        return dispatch('addExerciseSeriesToSession', { exercise, hasCachedSeries })
      }

      return dispatch('removeAllExercisesFromSessionByID', { exerciseID: exercise.id, numberOfExercisesToUpdate: -exercise.series })

    },
    updateAddedExerciseByID({ commit }, { id, payload }) {
      commit('updateAddedExerciseByID', { id, payload })
    },
    removeExerciseFromPrescription({ commit, getters, dispatch }, exercise) {
      const removedExercises = getters.getCurrentExercises.removed
      const updatedRemovedExercises = [...removedExercises, exercise.code]

      commit('setRemovedExercises', updatedRemovedExercises)

      const reAddedExercises = getters.getCurrentExercises.reAdded
      const updatedReAddedExercises = reAddedExercises.filter((code) => code !== exercise.code)

      commit('setReAddedExercises', updatedReAddedExercises)

      const isExerciseSelected = getters.isExerciseSelected(exercise.id)

      if (isExerciseSelected) {
        dispatch('removeAllExercisesFromSessionByID', { exerciseID: exercise.id, numberOfExercisesToUpdate: -exercise.series })
      }
    },
    reAddExerciseToPrescription({ commit, getters }, exercise) {
      const reAddedExercises = getters.getCurrentExercises.reAdded
      const updatedReAddedExercises = [...reAddedExercises, exercise.code]

      commit('setReAddedExercises', updatedReAddedExercises)

      const removedExercises = getters.getCurrentExercises.removed
      const updatedRemovedExercises = removedExercises.filter((code) => code !== exercise.code)

      commit('setRemovedExercises', updatedRemovedExercises)
    },
    async getExerciseConditions(_, { patientId, exerciseId }) {
      try {
        const { data } = await Vue.$http('prescription/patient/getExerciseConditions', {
          patientId,
          exerciseId,
          ignoreFromDefaultSessions: true,
        })

        return data
      } catch (error) {
        console.error(`[exercise-conditions] error fetching exercise conditions for exercise ${exerciseId}`, error)

        return error
      }
    },
    async calculateEstimatedSessionTime({ commit }, exercises = [{ code: 0, repeats: 0, series: 0 }]) {
      const groupedExercises = Object.values(exercises.reduce((grouped, currentExercise) => {
        if (!grouped[currentExercise.code]) {
          return { ...grouped, [currentExercise.code]: currentExercise }
        }
        grouped[currentExercise.code].series += currentExercise.series

        return grouped
      }, {}))

      try {
        const { data } = await Vue.$http('prescription/session/calculateEstimatedSessionTime', null, { body: { exercises: groupedExercises } })
        const formattedEstimatedTime = shDate(data.estimated_time, 'duration')

        commit('setEstimatedSessionTime', formattedEstimatedTime)

      } catch (error) {
        console.error('[estimate session time] error calculation estimated session time', error)
      }
    },
  },
}

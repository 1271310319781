import { ZodString } from 'zod'
import validationsRegex from '@sword-health/input-validation/dist/scripts/regex'

ZodString.prototype.validChars = function (regexValue) {
  return this.regex(regexValue)
}

ZodString.prototype.emailFormat = function () {
  return this.regex(validationsRegex.email.global)
}

ZodString.prototype.maxLengthMinusOffset = function (maxLengthParam, offset) {
  return this.refine((value) => String(value).length <= maxLengthParam + offset)
    .describe({ type: 'maxLength', params: { max: maxLengthParam } })
}

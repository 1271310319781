<template>
  <div id="chat-popup" :class="{ 'summary-sidebar-open': isMemberSidebarOpen }">
    <div :class="['chat-conversation-item', { opened: isChatPopupOpened }]">
      <div v-if="isChatPopupOpened" class="chat-conversation-item-header">
        <sword-button-wuk
          class="chat-conversation-item-header__close-button"
          theme="link"
          round
          size="small"
          icon="close"
          @click.native="closeChatPopup"
        />
      </div>
      <div v-show="isChatPopupOpened" class="chat-conversation-content">
        <iframe ref="iframeRef" :src="preferredChannelUrl" frameborder="0"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch } from 'vue'
import store from '@/store'

// Composables
import useChatIframe from '@/views/chat/composables/use-chat-iframe'

// Utils
import { triggers } from '@/scripts/global-modals-commands'

export default {
  name: 'ChatPopup',
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => {},
    },
    memberSidebarOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isChatPopupOpened = computed(() => props.modalOpen)
    const isMemberSidebarOpen = computed(() => props.memberSidebarOpen)
    const requiredPayload = computed(() => props.payload)

    const accountUuid = computed(() => store.getters['patient/patientChatPopup/getAccountUuid'])
    const unit = computed(() => store.getters['patient/patientChatPopup/getUnit'])
    const chatChannelUrl = computed(() => store.getters['patient/patientChatPopup/getChatChannelUrl'])

    watch(isChatPopupOpened, (val) => {
      // Closing chat popup
      if (!val) {
        store.commit('patient/patientChatPopup/setAccountUuid', null)
        store.commit('patient/patientChatPopup/setUnit', null)
        store.commit('patient/patientChatPopup/setChannelId', null)

        return
      }

      // Opening chat popup
      const { accountUuid, unit, channelId } = requiredPayload.value || {}

      store.commit('patient/patientChatPopup/setAccountUuid', accountUuid)
      store.commit('patient/patientChatPopup/setUnit', unit)
      store.commit('patient/patientChatPopup/setChannelId', channelId)
    })

    const iframeEventsHandler = ({ data: { type: eventType, payload } }) => {

      // Drop event that come from different tab instances like chat in other tab
      if (eventType !== 'ready' && !isChatPopupOpened.value) return

      switch (eventType) {
        case 'open:summary-patient':
          triggers.toggleMemberSidebar({ memberUuid: payload.patientId })
          break
        case 'close:chat-popup':
          closeChatPopup()
          break
        case 'ready':
          iframeIsReady.value = true
          break
        default:
          break
      }
    }

    const {
      iframeRef,
      iframeIsReady,
      initialIframeSrc,
      preferredChannelUrl,
      resetSelectedChatChannelUrl,
    } = useChatIframe({ accountUuid, unit, chatChannelUrl }, iframeEventsHandler, { enableIframeUrlUpdate: isChatPopupOpened })

    const closeChatPopup = () => {
      resetSelectedChatChannelUrl()
      triggers.closeChatPopup()
    }

    return {
      preferredChannelUrl,
      // Refs
      iframeRef,
      initialIframeSrc,
      // Computed
      isChatPopupOpened,
      isMemberSidebarOpen,
      // Methods
      closeChatPopup,
    }
  },
}
</script>

<style lang="scss" scoped>
#chat-popup {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  z-index: 50;
  display: flex;
  align-items: flex-end;
  gap: 1rem;

  &.summary-sidebar-open {
    right: 30rem;
  }
}

$chatHeight: 85vh;

.chat-conversation {
  &-item {
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.25);

    width: 265px;
    background: #fff;
    border-radius: 1rem;
    overflow: hidden;

    &.opened {
      width: 400px;
    }

    &-header {
      display: flex;
      justify-content: flex-end;
      padding: 1rem;
      border-bottom: 1px solid $context-color-7;

      &__close-button {
        position: absolute;
        top: 0;
        right: 0;

        ::v-deep {
          .sword-button-wuk.--small.round {
            margin: 0;
          }

          .sword-icon.svg-icon {
            height: 0.875rem !important;
            width: 0.875rem !important;
          }
        }
      }
    }
  }

  &-content {
    height: $chatHeight;
    display: flex;

    iframe {
      width: 100%
    }
  }
}
</style>

<template>
  <v-dropdown class="all-kits" :distance="10" placement="bottom-end">
    <sword-button-wuk
      v-if="items.length"
      theme="secondary"
      icon="box"
      size="small"
      side="left"
      text="button.all_kits"
      class="-mx_0"
    />
    <template #popper>
      <div class="all-kits-popover">
        <header>
          <span>{{ $t('SIDEBAR.KIT') }}</span>
          <span>{{ $t('PATIENTS.STATUS') }}</span>
          <span>{{ $t('COMMON.LAST_UPDATE') }}</span>
        </header>
        <div v-for="(item, itemIndex) of itemsParsed" :key="itemIndex" class="kit-item">
          <span class="t2 -t_medium">{{ $t(item.product_type) }}</span>
          <div class="flex">
            <kit-status-pill :status="item.status" />
          </div>
          <span class="t2 -t_medium">{{ item.updated_at }}</span>
        </div>
      </div>
    </template>
  </v-dropdown>
</template>

<script>
import { computed } from 'vue'
import { $date } from '@/plugins/dayjs'

import { Dropdown as VDropdown } from 'floating-vue'

export default {
  components: { VDropdown },
  props: {
    items: { type: Array, default: () => [] },
  },
  setup(props) {
    const itemsParsed = computed(() => props.items?.map((item) => ({
      ...item,
      updated_at: item.updated_at ? $date(item.updated_at).format('MMM D, YYYY, HH:mm') : '-',
    })) || [])

    return { itemsParsed }
  },
}
</script>

<style lang="scss" scoped>
@import '~floating-vue/dist/style.css';

.all-kits {
  position: relative;
  display: inline-block;
  margin-left: auto;

  &-popover {
    padding: 1rem;
    width: 30rem;

    .kit-item, header {
      display: grid;
      grid-template-columns: 1fr 1fr 11rem;
      grid-gap: 1rem;
    }

    header {
      margin-bottom: $space-sm;
      color: $color-grey-600;
    }

    .kit-item {
      margin-bottom: $space-xs;
    }
  }
}
</style>

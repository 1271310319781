import type { VueConstructor } from 'vue'

import PulseLoaderWuk from '@ui-kit/components/loaders/PulseLoader.vue'
import PulseLoaderV2 from '@ui-kit/components/loaders/PulseLoaderV2.vue'
import SwordIconWuk from '@ui-kit/components/icons/SwordIcon.vue'
import SwordTagWuk from '@ui-kit/components/tag/Tag.vue'
import ChipWuk from '@ui-kit/components/Chip.vue'
import SwordTooltip from '@ui-kit/components/tooltips/SwordTooltip.vue'
import HoverToCopy from '@ui-kit/components/micro/HoverToCopy.vue'
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'
import ProfilePicture from '@ui-kit/components/pictures/ProfilePicture.vue'
import SwordAlertWuk from '@ui-kit/components/alert/Alert.vue'
import MenuWuk from '@ui-kit/components/menus/Menu.vue'

import GoalStatusPill from '@ui-kit/components/micro/GoalStatusPill.vue'
import KitStatusPill from '@ui-kit/components/micro/KitStatusPill.vue'
import TemporaryTransferPill from '@ui-kit/components/micro/TemporaryTransferPill.vue'

import PatientAttentionLevel from '@ui-kit/components/attention-level/PatientAttentionLevel.vue'

// Inputs
import BaseInputWuk from '@ui-kit/components/inputs/BaseInput.vue'
import SwordTextWuk from '@ui-kit/components/inputs/SwordText.vue'
import SwordSearchWuk from '@ui-kit/components/inputs/SwordSearch.vue'
import SwordPhoneWuk from '@ui-kit/components/inputs/SwordPhone.vue'
import SwordMeasureWuk from '@ui-kit/components/inputs/SwordMeasure.vue'
import SwordPasswordWuk from '@ui-kit/components/inputs/SwordPassword.vue'
import SwordTextareaWuk from '@ui-kit/components/inputs/SwordTextarea.vue'
import SwordDateWuk from '@ui-kit/components/inputs/date/SwordDate.vue'
import SwordDropdownWuk from '@ui-kit/components/inputs/dropdowns/SwordDropdown.vue'
import SwordFormattedDateWuk from '@ui-kit/components/inputs/date/SwordFormattedDate.vue'
import SwordToggleWuk from '@ui-kit/components/inputs/checkboxes/SwordToggle.vue'
import SwordCheckboxWuk from '@ui-kit/components/inputs/checkboxes/SwordCheckbox.vue'
import SwordCheckboxGroupWuk from '@ui-kit/components/inputs/checkboxes/SwordCheckboxGroup.vue'
import SwordRadioGroupWuk from '@ui-kit/components/inputs/radios/SwordRadioGroup.vue'
import SwordFileUploadWuk from '@ui-kit/components/inputs/SwordFileUpload.vue'
import InputLabelWuk from '@ui-kit/components/inputs/labels/InputLabel.vue'
import InputErrorWuk from '@ui-kit/components/inputs/footnotes/InputError.vue'
import MultipleInputEntriesWuk from '@ui-kit/components/inputs/MultipleInputEntries.vue'

const wukComponents = {
  'PulseLoaderWuk': PulseLoaderWuk,
  'PulseLoaderV2': PulseLoaderV2,
  'SwordIcon': SwordIconWuk,
  'SwordTextWuk': SwordTextWuk,
  'SwordButtonWuk': SwordButtonWuk,
  'ProfilePicture': ProfilePicture,
  'SwordCheckboxWuk': SwordCheckboxWuk,
  'SwordTagWuk': SwordTagWuk,
  'SwordTooltip': SwordTooltip,
  'HoverToCopy': HoverToCopy,
  'SwordDropdownWuk': SwordDropdownWuk,
  'SwordTextareaWuk': SwordTextareaWuk,
  'SwordToggleWuk': SwordToggleWuk,
  'SwordCheckboxGroupWuk': SwordCheckboxGroupWuk,
  'SwordPasswordWuk': SwordPasswordWuk,
  'SwordRadioGroupWuk': SwordRadioGroupWuk,
  'SwordSearchWuk': SwordSearchWuk,
  'SwordPhoneWuk': SwordPhoneWuk,
  'InputLabelWuk': InputLabelWuk,
  'InputErrorWuk': InputErrorWuk,
  'SwordMeasureWuk': SwordMeasureWuk,
  'BaseInputWuk': BaseInputWuk,
  'SwordDateWuk': SwordDateWuk,
  'SwordFormattedDateWuk': SwordFormattedDateWuk,
  'MultipleInputEntriesWuk': MultipleInputEntriesWuk,
  'SwordFileUploadWuk': SwordFileUploadWuk,
  'SwordAlertWuk': SwordAlertWuk,
  'ChipWuk': ChipWuk,
  'MenuWuk': MenuWuk,
  'GoalStatusPill': GoalStatusPill,
  'KitStatusPill': KitStatusPill,
  'TemporaryTransferPill': TemporaryTransferPill,
  'PatientAttentionLevel': PatientAttentionLevel,
}

export default {
  install(Vue: VueConstructor) {
    Object.entries(wukComponents).forEach(([name, component]) => {
      Vue.component(name, component)
    })
  },
}

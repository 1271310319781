<template>
  <section class="notes-list-wrapper">
    <div v-if="!notes.length" class="empty-feedback">
      <img :src="require('@ui-kit/assets/images/feedback/empty-notes.svg')" :alt="$t('copy_2675')" />
      <p class="-t_medium -mt_2 text">{{ $t('copy_2675') }}</p>
    </div>
    <ul v-else class="-px_2 -pt_2 -pb_1 list-container">
      <note-item
        v-for="note in notes"
        :key="note.id"
        class="-mb_1"
        :title="note.title"
        :date="note.formattedLastEditDate"
        @open="selectNote(note)"
        @delete="deleteNote(note.id)"
      />
    </ul>
    <sword-button-wuk text="copy_2674" icon="add" side="left" @click.native="$emit('new-note')" />
  </section>
</template>

<script>
// Components
import NoteItem from './NoteItem.vue'

export default {
  name: 'NotesList',
  components: {
    NoteItem,
  },
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['open-note', 'delete-note'],
  setup(props, { emit }) {

    const selectNote = (note) => {
      emit('open-note', note)
    }

    const deleteNote = (note) => {
      emit('delete-note', note)
    }

    return {
      selectNote,
      deleteNote,
    }
  },
}
</script>

<style lang="scss" scoped>
.notes-list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .list-container {
    flex: 1 1 auto;
    overflow-y: auto;
    @include custom-scrollbar-wuk(getColor($blues-wuk, 5), 5px, getColor($blues-wuk, 2));
  }

  .empty-feedback {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      color: $context-color-5;
    }
  }
}
</style>

<template>
  <div class="full-screen-feedback-container">
    <base-feedback-screen :transparent="transparent">
      <pulse-loader-v2 v-if="newLoader" />
      <pulse-loader-wuk v-else />
      <p v-if="text" class="t1 -mx_0 -mt_1 -mb_0">
        {{ $t(text) }}
      </p>
    </base-feedback-screen>
  </div>
</template>

<script>
import BaseFeedbackScreen from '@/components/feedbacks/boilerPlate/BaseFeedbackScreen.vue'

export default {
  name: 'LoadingScreen',
  components: {
    BaseFeedbackScreen,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    newLoader: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.full-screen-feedback-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>

import type { VueConstructor } from 'vue'
import dayjs, { type Dayjs, type ConfigType } from 'dayjs'
import 'dayjs/locale/pt'

import pluginUtc from 'dayjs/plugin/utc'
import pluginTimezone from 'dayjs/plugin/timezone'
import pluginLocalizedFormat from 'dayjs/plugin/localizedFormat'
import pluginIsBetween from 'dayjs/plugin/isBetween'
import pluginCustomParseFormat from 'dayjs/plugin/customParseFormat'
import pluginRelativeTime from 'dayjs/plugin/relativeTime'
import pluginWeekOfYear from 'dayjs/plugin/weekOfYear'
import pluginDuration from 'dayjs/plugin/duration'

import i18n from '@/scripts/app-configs/i18n-config'
import { preferredLanguage } from '@/scripts/app-configs/language/languages-configs'

dayjs.locale(preferredLanguage)

dayjs.extend(pluginUtc)
dayjs.extend(pluginTimezone)
dayjs.extend(pluginLocalizedFormat)
dayjs.extend(pluginIsBetween)
dayjs.extend(pluginCustomParseFormat)
dayjs.extend(pluginRelativeTime)
dayjs.extend(pluginWeekOfYear)
dayjs.extend(pluginDuration)

const $date = dayjs

$date.prototype.divide = function divideDate() {
  return {
    day: this.date(),
    month: this.month(),
    year: this.year(),
    month_name: this.format('MMMM'),
  }
}

$date.prototype.shToday = function checkIfIsToday(d: ConfigType) {
  const date = dayjs(d)

  return dayjs().isSame(date, 'day')
}

$date.prototype.shYesterday = function shYesterday(d: ConfigType) {
  const date = dayjs(d)

  return dayjs().subtract(1, 'day').isSame(date, 'day')
}

$date.prototype.shDayDiff = function shDayDiff(d = new Date) {
  const date = dayjs(d).set('hour', 0).set('minute', 0).set('second', 0)
  const dateOrigin = dayjs(this.$d).set('hour', 0).set('minute', 0).set('second', 0)

  return dateOrigin.diff(date, 'day')
}

$date.prototype.dateDetails = function generateDateDetails(format?: string) {
  const date = dayjs(new Date(this * 1000))
  const dateMonth = date.get('month') + 1
  const dateYear = date.get('year')
  const dateTimestamp = date.unix()
  const dateUtcTimestamp = dateTimestamp + new Date(this).getTimezoneOffset() * 60
  const dateUtcDate = dayjs(new Date(dateUtcTimestamp * 1000))

  return {
    isToday: date.shToday(date),
    isYesterday: date.shYesterday(date),
    day: date.get('date'),
    month: dateMonth,
    year: dateYear,
    daysInMonth: new Date(dateYear, dateMonth, 0).getDate(),
    hour: date.get('hour'),
    minutes: date.get('minute'),
    seconds: date.get('second'),
    dateFormatted: date.format(format),
    timestamp: dateTimestamp,
    timezone: (new Date(this).getTimezoneOffset() * -1) / 60,
    utcTimestamp: dateUtcTimestamp,
    utcDate: dateUtcDate,
    utcFormatted: dateUtcDate.format(format),
  }
}

$date.prototype.defaultDiff = function defDiff(d: ConfigType, ignoreTime = false) {
  let date = dayjs(d)
  let refDate = this

  if (ignoreTime) {
    refDate = refDate.startOf('day')
    date = date.startOf('day')
  }

  const diff = refDate.diff(date, 'day')

  switch (diff) {
    case 0: {
      return i18n.t('LATEST_ACTIVITY.TODAY')
    }
    case 1: {
      return i18n.t('LATEST_ACTIVITY.YESTERDAY')
    }
    default: {
      return `${diff} ${i18n.t('COMMON.DAYS_AGO')}`
    }
  }
}

$date.prototype.verboseDate = function formatVerbose(format?: string) {
  const today = i18n.t('LATEST_ACTIVITY.TODAY')
  const yesterday = i18n.t('LATEST_ACTIVITY.YESTERDAY')
  const pastDate = this.format(format)
  let verboseDate = pastDate

  if (this.shToday(this)) {
    verboseDate = today
  }

  if (this.shYesterday(this)) {
    verboseDate = yesterday
  }

  const hour = `${String(this.get('hour')).padStart(2, '0')}:${String(this.get('minute')).padStart(2, '0')}h`

  return `${verboseDate}, ${hour}`
}

$date.prototype.formatDuration = function formatDuration(secondsRaw: number) {
  const duration = dayjs.duration(secondsRaw, 'seconds')
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  if (seconds === 0) return minutes.toString()

  return `${minutes}:${seconds.toString().padStart(2, '0')}`
}

export { $date, Dayjs }

export default {
  install(Vue: VueConstructor) {
    Vue.prototype.$date = $date
    Vue.$date = $date
  },
}

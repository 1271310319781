<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { ref, type Ref, onMounted, getCurrentInstance } from 'vue'

const props = withDefaults(defineProps<{
  title: string
  collapseOnClickOutside?: boolean
  collapsedOnOpen?: boolean,
  ignoreClickOutsideRefs?: Ref[] // Ignore clicks outside directly from component references
  ignoreClickOutsideUiActions?: string | string[], // Ignore clicks outside through data-ui-action custom attrs
}>(), { collapseOnClickOutside: true, collapsedOnOpen: false })

const emit = defineEmits<{
  (e: 'collapse', state: boolean): void
  (e: 'close'): void
}>()

const panelRef = ref(null)
const panelCollapsed = ref(props.collapsedOnOpen)

const togglePanelCollapsing = () => {
  panelCollapsed.value = !panelCollapsed.value
  emit('collapse', panelCollapsed.value)
}

const closePanel = () => {
  emit('close')
}

onClickOutside(panelRef, (ev) => {
  // By providing ignoreClickOutsideUiActions, we need to check if the event is to be ignored based on its custom data-ui-action value
  let ignoreEventByGivenUiAction = false

  // Existing target to work with and provided list/string of custom ui-action attr to be ignored
  if (ev.target && props.ignoreClickOutsideUiActions) {
    // Search for data-ui-action on target or any of its parents (i.e. target could be an SVG inside a button that actually has the ui-action attr)
    const uiActionEl = (ev.target as HTMLElement).closest('[data-ui-action]')
    // Normalizing data-ui-action values to check as an Array for consistency
    const uiActions = Array.isArray(props.ignoreClickOutsideUiActions) ? props.ignoreClickOutsideUiActions : [props.ignoreClickOutsideUiActions]

    // Parent element with defined data-ui-action found and its value does match (the)one of the provided values
    if (uiActionEl && uiActions.some((action) => action === (uiActionEl as HTMLElement).dataset.uiAction)) {
      ignoreEventByGivenUiAction = true
    }
  }
  if (!props.collapseOnClickOutside || panelCollapsed.value || ignoreEventByGivenUiAction) return
  togglePanelCollapsing()
}, { ignore: props.ignoreClickOutsideRefs || [] })

onMounted(() => {

  const instance = getCurrentInstance()

  // Exposing collapsing state and manual collapse toggle trigger to parent component for better UI flexibility
  if (instance && instance.proxy) {
    (instance.proxy as unknown as Record<string, unknown>).panelCollapsed = panelCollapsed as Ref<boolean>
    (instance.proxy as unknown as Record<string, unknown>).togglePanelCollapsing = togglePanelCollapsing
  }
})
</script>

<template>
  <div ref="panelRef" class="floating-widget">

    <div class="floating-widget__header">
      <p class="floating-widget__header__title t3 -t_semi-bold -pr_2 -mb_0"> {{ title }} </p>
      <div class="floating-widget__header__actions">
        <sword-button-wuk
          icon="minus"
          theme="transparent"
          size="medium"
          round
          @click.native="togglePanelCollapsing"
        />
        <sword-button-wuk
          icon="close"
          theme="transparent"
          size="medium"
          round
          @click.native="closePanel"
        />
      </div>
    </div>

    <transition name="collapse">
      <div v-if="!panelCollapsed" class="floating-widget__content">
        <slot></slot>
      </div>
    </transition>

  </div>
</template>

<style scoped lang="scss">

$max-widget-height: 85vh;

.floating-widget {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 50;
  display: flex;
  background: white;
  width: 25rem;
  max-height: $max-widget-height;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 6px 18px 0 rgba(31, 34, 44, 0.25);

  &__header {
    display: flex;
    padding: $space-sm;
    align-items: center;

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__actions {
      margin-left: auto;
      flex-shrink: 0;
    }
  }

  &__content {
    overflow: auto;
    flex-grow: 1;
    padding: 0 1rem;
  }
}

.floating-widget__content {
  transform-origin: bottom;
}
.collapse-enter-active {
  overflow: hidden;
  animation: collapse reverse 250ms ease;
}
.collapse-leave-active {
  overflow: hidden;
  animation: collapse 250ms ease;
}
@keyframes collapse {
  from {
    max-height: $max-widget-height;
  }
  to {
    max-height: 0;
  }
}
</style>

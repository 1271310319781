import { TASK_TYPE } from '@/views/ai-feed/constants/defaults'
const DEBUG_TASKS_DONE = false

export default {
  fetchMasterTasksList: {
    api: {
      url: 'default',
      version: 1,
    },
    $$method: 'get',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      forceMock: DEBUG_TASKS_DONE,
      mock: (_, userId) => {
        console.log('Getting mock UML master tasks for user with Id: ', userId)

        return 'mocks/endpoints/unified-members-list/fetchMasterTasksList.json'
      },
    },
    $$makeUrl(configs, { offset, limit, search, filterBy, searchParams, onlyReplyToMember }) {
      const url = `/therapist/v${configs.api.version}/feed/tasks`
      const queryParams = new URLSearchParams(searchParams)

      onlyReplyToMember ?
        queryParams.append('only_types', [TASK_TYPE.PENDING_REPLY]) :
        queryParams.append('not_only_types', [TASK_TYPE.PENDING_REPLY])

      if (offset !== undefined) {
        queryParams.append('offset', offset)
      }

      if (limit) queryParams.append('limit', limit)
      if (queryParams.has('filters', '')) queryParams.delete('filters')

      if (search) {
        queryParams.append('search', search)
        queryParams.append('search_type', filterBy)
      }

      return `${url}?${queryParams}`

    },
  },
  fetchMasterTask: {
    api: {
      url: 'default',
      version: 2,
    },
    $$method: 'get',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      forceMock: false,
      mock: () => {
        return 'mocks/endpoints/unified-members-list/fetchMasterTaskReachOutsVM.json'
      },
    },
    $$makeUrl(configs, masterTaskId) {
      return `/therapist/v${configs.api.version}/uml/master-tasks/${masterTaskId}`
    },
  },
  preFetchGutenbergReachOutInMasterTask: {
    api: {
      url: 'default',
      version: 1,
    },
    $$method: 'get',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      forceMock: false,
      mock: () => {
        return 'mocks/endpoints/unified-members-list/fetchMasterTask.json'
      },
    },
    $$makeUrl(configs, masterTaskId) {
      return `/therapist/v${configs.api.version}/ai-feed/master-task/${masterTaskId}/preload-gutenberg`
    },
  },
  snoozeMemberMasterTask: {
    api: {
      url: 'default',
      version: 1,
    },
    $$method: 'put',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, masterTaskId) {
      return `/therapist/v${configs.api.version}/uml/master-tasks/${masterTaskId}/status`
    },
  },
  flagSubTaskAsDismissed: {
    $$method: 'put',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      forceMock: DEBUG_TASKS_DONE,
      mock: () => 'mocks/endpoints/requestResponses/emptyHit.json',
    },
    $$makeUrl: (configs, url) => url,
  },
  flagSubTaskAsDismissedAsPost: {
    $$method: 'post',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      forceMock: DEBUG_TASKS_DONE,
      mock: () => 'mocks/endpoints/requestResponses/emptyHit.json',
    },
    $$makeUrl: (configs, url) => url,
  },
  applyExclusionRequestAction: {
    api: {
      url: 'default',
      version: 1,
    },
    $$method: 'post',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      forceMock: DEBUG_TASKS_DONE,
      mock: () => 'mocks/endpoints/requestResponses/emptyHit.json',
    },
    $$makeUrl: (configs, { subtaskUuid, actionKey }) => {
      return `therapist/v${configs.api.version}/exclusion-requests/${subtaskUuid}/status/${actionKey}`
    },
  },
  updatePendingPlanOfCareNote: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'put',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, pendingPlanOfCareUuid) {
      return `therapist/v${configs.api.version}/pending-plan-of-care-notes/${pendingPlanOfCareUuid}`
    },
  },
  bulkSuggestedActions: {
    api: {
      url: 'default',
      version: 1,
    },
    $$method: 'post',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs) {
      return `therapist/v${configs.api.version}/uml/taskinhas/bulk-suggested-actions`
    },
  },
  sendGutenbergReachOutAutoSuggestedMessage: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'post',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, reachOutUuid) {
      return `therapist/v${configs.api.version}/gutenberg-reachouts/${reachOutUuid}/complete`
    },
  },
  sendAcademySuggestion: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'put',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, subtaskUuid) {
      return `therapist/v${configs.api.version}/uml/taskinhas/${subtaskUuid}/messages/mark-as-done`
    },
  },
  sendClinicalReferralAction: {
    api: {
      url: 'default',
      version: 1,
    },
    $$method: 'post',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { subtaskId, referralUuid }) {
      return `therapist/v${configs.api.version}/ai-feed/taskinhas/${subtaskId}/referrals/${referralUuid}`
    },
  },
  newsfeedList: {
    api: {
      url: 'default',
      version: 1,
    },
    $$method: 'get',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUuid, status }) {
      return `therapist/v${configs.api.version}/ai-feed/newsfeed?status=${status}&program_uuid=${programUuid}`
    },
  },
  archiveNewsfeedItem: {
    api: {
      url: 'default',
      version: 1,
    },
    $$method: 'put',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { newsfeedItemId }) {
      return `therapist/v${configs.api.version}/ai-feed/newsfeed/${newsfeedItemId}/archive`
    },
  },
}
